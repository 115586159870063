import {
  Action,
  Actions,
  ofAction,
  Selector,
  State,
  StateContext,
  StateToken,
  Store,
} from '@ngxs/store';
import { Injectable, inject } from '@angular/core';

import {
  Command,
  FirebaseChat,
  FirebaseChatMessage,
  MissionTypeEnum,
  Team,
} from '@freddy/models';
import { tap } from 'rxjs/operators';
import { Conversation, ConversationMessage } from '../chat.model';
import { GameState, GameStateModel } from '../../game/store/game.store';
import { ChatRepository } from '../repository/chat.repository';
import {
  ClearListenToGameChangesAction,
  GameCreatedAction,
  ListenToGameChangesAction,
} from '../../game/actions/game.action';
import {
  AddPointsAction,
  CreateChatAction,
  DisconnectPlayerAction,
  ListenMessagesAction,
  RemovePointsAction,
  RestartApplicationAction,
  SaveMessageAction,
  SelectChannelAction,
  SelectChatAction,
  SendMessageToUserAction,
} from '../actions/chat.actions';
import { firstValueFrom, takeUntil } from 'rxjs';
import { GuidUtils, RestartApplicationCommandAction } from 'common';
import { ChatMessageRepository } from '../repository/chat-message.repository';
import { AnswerRepository } from '../../game/repository/answer.repository';
import { CommandRepository } from '../../game/repository/command.repository';
import { HotToastService } from '@ngneat/hot-toast';
import { TranslateService } from '@ngx-translate/core';
import { DisconnectPlayerCommandAction } from '../../../../../../common/src/lib';
import { TeamRepository } from '../../game/repository/team.repository';

export const CHAT_STATE_TOKEN = new StateToken<ChatStateModel>('chat');

export interface NewMessage {
  chatUid: string;
  message: string;
  gameUid: string;
  metadata?: Record<string, string>;
}

export interface ChatStateModel {
  gameUid?: string;
  chats: FirebaseChat[];
  messages: {
    [key: string]: FirebaseChatMessage[];
  };
  loading: boolean;
  selectedChatUid?: string;
}

const defaultFormValue = {
  chats: [],
  messages: {},
  loading: false,
};

@State<ChatStateModel>({
  name: CHAT_STATE_TOKEN,
  defaults: {
    ...defaultFormValue,
  },
})
@Injectable()
export class ChatState {
  private readonly chatRepository = inject(ChatRepository);
  private readonly chatMessageRepository = inject(ChatMessageRepository);
  private readonly answerRepository = inject(AnswerRepository);
  private readonly store = inject(Store);
  private actions$ = inject(Actions);
  private readonly commandRepository = inject(CommandRepository);
  private readonly toast = inject(HotToastService);
  private readonly translate = inject(TranslateService);
  private readonly teamRepository = inject(TeamRepository);

  public static ADMIN_CHAT_ID = 'ADMIN';

  @Selector()
  static selectedChat(state: ChatStateModel): string | undefined {
    return state.selectedChatUid;
  }

  @Selector()
  static channels(state: ChatStateModel): Conversation[] {
    return state.chats
      .filter((chat) => chat.isChannel)
      .map((chat) => {
        return {
          id: chat.uid,
          name: chat.name,
          participants: [],
          profilePicture: '',
          status: 'online',
          unRead: false,
          lastMessage: chat.lastMessage,
          lastMessageAt: chat.lastMessageAt,
          isTyping: chat.isTyping,
          isChannel: chat.isChannel,
        };
      });
  }

  @Selector([ChatState, GameState.controlTeams])
  static messages(state: ChatStateModel, teams: Team[]): ConversationMessage[] {
    const chatUid = state.selectedChatUid;
    const getMessages = (chatUid?: string) => {
      if (chatUid && state.messages[chatUid]) {
        return state.messages[chatUid];
      }
      return [];
    };

    return getMessages(chatUid).map((message): ConversationMessage => {
      return {
        id: message.uid,
        message: message.message,
        time: message.createdAt,
        profilePicture: teams.find((team) => team.uid === message.senderUid)
          ?.teamPhoto,
        senderName:
          teams.find((team) => team.uid === message.senderUid)?.teamName ??
          'ADMIN',
        align: message.senderUid === ChatState.ADMIN_CHAT_ID ? 'right' : 'left',
        metadata: message.metadata,
      };
    });
  }

  @Selector([ChatState, ChatState.conversations])
  static unreadConversations(
    state: ChatStateModel,
    conversations: Conversation[],
  ): number {
    return conversations.filter((conversation) => conversation.unRead).length;
  }

  @Selector([ChatState, GameState.controlTeams])
  static conversations(state: ChatStateModel, teams: Team[]): Conversation[] {
    return teams.map((team) => {
      const chat = state.chats.find((chat) =>
        chat.participants.includes(team.uid),
      );
      return {
        id: chat?.uid,
        name: team.teamName,
        teamUid: team.uid,
        participants: [team.uid, ChatState.ADMIN_CHAT_ID],
        profilePicture: team.teamPhoto,
        status: team.online ? 'online' : 'offline',
        unRead:
          !chat?.readBy?.includes(ChatState.ADMIN_CHAT_ID) &&
          !!chat?.lastMessageAt,
        lastMessage: chat?.lastMessage,
        lastMessageAt: chat?.lastMessageAt,
        isTyping: chat?.isTyping,
        isChannel: chat?.isChannel ?? false,
        borderColor: team.color,
      };
    });
  }

  @Action(GameCreatedAction)
  async gameCreated(
    ctx: StateContext<GameStateModel>,
    action: GameCreatedAction,
  ) {
    await firstValueFrom(
      this.chatRepository.create(
        {
          uid: GuidUtils.generateUuid(),
          participants: [ChatState.ADMIN_CHAT_ID],
          readBy: [],
          name: 'General',
          lastMessageAt: null,
          isTyping: false,
          isChannel: true,
        },
        {
          gameUid: action.game.uid,
        },
      ),
    );
  }

  @Action(SendMessageToUserAction)
  async sendMessageToUserAction(
    ctx: StateContext<ChatStateModel>,
    action: SendMessageToUserAction,
  ) {
    const chats = ctx.getState().chats;
    const gameUid = ctx.getState().gameUid;
    const chat = chats.find(
      (chat) =>
        chat.participants.includes(action.teamUid) &&
        chat.participants.includes(ChatState.ADMIN_CHAT_ID),
    );
    let chatUid;
    if (chat) {
      chatUid = chat.uid;
    } else if (gameUid) {
      const newChat = await this.createChat(action.teamUid, gameUid);
      chatUid = newChat.uid;
    }
    if (chatUid && gameUid) {
      await this.addMessageToChat({
        chatUid,
        message: action.message,
        gameUid,
        metadata: action.metadata,
      });
    }
  }

  @Action(SaveMessageAction)
  async saveMessageAction(
    ctx: StateContext<ChatStateModel>,
    action: SaveMessageAction,
  ) {
    const selectedChat = ctx
      .getState()
      .chats.find((c) => c.uid === ctx.getState().selectedChatUid);
    const gameUid = ctx.getState().gameUid;
    if (selectedChat && gameUid) {
      await this.addMessageToChat({
        chatUid: selectedChat.uid,
        message: action.message,
        gameUid,
      });
    }
  }

  async addMessageToChat(newMessage: NewMessage) {
    await firstValueFrom(
      this.chatRepository.update(
        {
          uid: newMessage.chatUid,
          lastMessageAt: new Date(),
          lastMessage: newMessage.message,
          readBy: [ChatState.ADMIN_CHAT_ID],
        },
        {
          gameUid: newMessage.gameUid,
        },
      ),
    );

    await firstValueFrom(
      this.chatMessageRepository.create(
        {
          uid: GuidUtils.generateUuid(),
          message: newMessage.message,
          senderUid: ChatState.ADMIN_CHAT_ID,
          metadata: newMessage.metadata,
        },
        {
          chatUid: newMessage.chatUid,
          gameUid: newMessage.gameUid,
        },
      ),
    );
  }

  @Action(ListenToGameChangesAction, { cancelUncompleted: true })
  listenChatChangesAction(
    ctx: StateContext<ChatStateModel>,
    action: ListenToGameChangesAction,
  ) {
    ctx.patchState({
      gameUid: action.gameUid,
    });
    return this.chatRepository.getChats(action.gameUid).pipe(
      takeUntil(this.actions$.pipe(ofAction(ClearListenToGameChangesAction))),
      tap((chats) => {
        ctx.patchState({
          chats: chats,
        });
      }),
    );
  }

  @Action(CreateChatAction, { cancelUncompleted: true })
  async createChatAction(
    ctx: StateContext<ChatStateModel>,
    action: CreateChatAction,
  ) {
    const gameUid = ctx.getState().gameUid;

    if (gameUid) {
      const chat = await this.createChat(action.teamUid, gameUid);
      this.store.dispatch(new ListenMessagesAction(chat.uid));
    }
  }

  createChat(teamUid: string, gameUid: string) {
    const newChat: FirebaseChat = {
      uid: GuidUtils.generateUuid(),
      participants: [teamUid, ChatState.ADMIN_CHAT_ID],
      readBy: [],
      lastMessageAt: null,
      isTyping: false,
      isChannel: false,
    };
    return firstValueFrom(
      this.chatRepository.create(newChat, {
        gameUid: gameUid,
      }),
    );
  }

  @Action(ListenMessagesAction, { cancelUncompleted: true })
  async listenMessagesAction(
    ctx: StateContext<ChatStateModel>,
    action: ListenMessagesAction,
  ) {
    const gameUid = ctx.getState().gameUid;
    if (gameUid) {
      ctx.patchState({
        selectedChatUid: action.chatUid,
      });

      // Mark chat as read
      const selectedChat = ctx
        .getState()
        .chats.find((c) => c.uid === action.chatUid); // Use action.chatUid directly
      if (
        selectedChat &&
        !selectedChat.readBy?.includes(ChatState.ADMIN_CHAT_ID)
      ) {
        await firstValueFrom(
          this.chatRepository.update(
            {
              ...selectedChat,
              readBy: [...(selectedChat.readBy ?? []), ChatState.ADMIN_CHAT_ID],
            },
            {
              gameUid: gameUid,
            },
          ),
        );
      }

      // Clear existing messages for this chat before fetching new ones
      return this.chatMessageRepository
        .getMessages(gameUid, action.chatUid)
        .pipe(
          tap((messages) => {
            ctx.patchState({
              messages: {
                ...ctx.getState().messages,
                [action.chatUid]: messages,
              },
            });
          }),
        );
    }
    return;
  }

  @Action(SelectChatAction, { cancelUncompleted: true })
  async selectChatAction(
    ctx: StateContext<ChatStateModel>,
    action: SelectChatAction,
  ) {
    const chats = ctx.getState().chats;
    const chat = chats.find(
      (chat) =>
        chat.participants.includes(action.teamUid) &&
        chat.participants.includes(ChatState.ADMIN_CHAT_ID),
    );
    if (!chat) {
      this.store.dispatch(new CreateChatAction(action.teamUid));
    } else {
      this.store.dispatch(new ListenMessagesAction(chat.uid));
    }
  }

  @Action(SelectChannelAction, { cancelUncompleted: true })
  async selectChannelAction(
    ctx: StateContext<ChatStateModel>,
    action: SelectChannelAction,
  ) {
    const chat = ctx
      .getState()
      .chats.find((chat) => chat.uid === action.chatId);
    if (chat) this.store.dispatch(new ListenMessagesAction(chat.uid));
  }

  @Action(AddPointsAction)
  addPointsAction(ctx: StateContext<ChatStateModel>, action: AddPointsAction) {
    const state = ctx.getState();
    const gameUid = state.gameUid;
    const selectedChat = ctx
      .getState()
      .chats.find((c) => c.uid === ctx.getState().selectedChatUid);
    if (selectedChat && gameUid) {
      selectedChat.participants
        .filter((participant) => participant !== ChatState.ADMIN_CHAT_ID)
        .forEach((participant) => {
          ctx.dispatch(new SaveMessageAction('TECH_MESSAGE.won5Points'));
          this.answerRepository.add(
            {
              uid: GuidUtils.generateUuid(),
              points: 5,
              isEvaluated: true,
              skipped: false,
              answer: '',
              missionType: MissionTypeEnum.OPEN,
              teamUid: participant,
              createdAt: new Date(),
            },
            { gameUid: gameUid },
          );
        });
    }
  }

  @Action(RemovePointsAction)
  removePointsAction(
    ctx: StateContext<ChatStateModel>,
    action: AddPointsAction,
  ) {
    const state = ctx.getState();
    const gameUid = state.gameUid;
    const selectedChat = ctx
      .getState()
      .chats.find((c) => c.uid === ctx.getState().selectedChatUid);
    if (selectedChat && gameUid) {
      selectedChat.participants
        .filter((participant) => participant !== ChatState.ADMIN_CHAT_ID)
        .forEach((participant) => {
          ctx.dispatch(new SaveMessageAction('TECH_MESSAGE.lost5Points'));
          this.answerRepository.add(
            {
              uid: GuidUtils.generateUuid(),
              points: -5,
              isEvaluated: true,
              skipped: false,
              answer: '',
              missionType: MissionTypeEnum.OPEN,
              teamUid: participant,
              createdAt: new Date(),
            },
            { gameUid: gameUid },
          );
        });
    }
  }

  @Action(RestartApplicationAction)
  restartApplicationAction(
    ctx: StateContext<ChatStateModel>,
    action: RestartApplicationAction,
  ) {
    const actionToDispatch = new RestartApplicationCommandAction();

    const state = ctx.getState();
    const gameUid = state.gameUid;
    const selectedChat = ctx
      .getState()
      .chats.find((c) => c.uid === ctx.getState().selectedChatUid);
    if (selectedChat && gameUid) {
      selectedChat.participants
        .filter((participant) => participant !== ChatState.ADMIN_CHAT_ID)
        .forEach((teamUid) => {
          const command: Command = {
            uid: GuidUtils.generateUuid(),
            recipientTeamUid: teamUid,
            action: actionToDispatch,
            executed: false,
            highPriority: true,
          };
          this.commandRepository.sendCommand(command, {
            gameUid: gameUid,
          });
          this.toast.success(
            this.translate.instant(
              'chat.specialActions.restartApplication.toast.success',
            ),
          );
        });
    }
  }

  @Action(DisconnectPlayerAction)
  disconnectPlayerAction(
    ctx: StateContext<ChatStateModel>,
    action: DisconnectPlayerAction,
  ) {
    const actionToDispatch = new DisconnectPlayerCommandAction();
    const state = ctx.getState();
    const gameUid = state.gameUid;
    const selectedChat = ctx
      .getState()
      .chats.find((c) => c.uid === ctx.getState().selectedChatUid);
    if (selectedChat && gameUid) {
      selectedChat.participants
        .filter((participant) => participant !== ChatState.ADMIN_CHAT_ID)
        .forEach((teamUid) => {
          this.teamRepository.update(
            {
              uid: teamUid,
              // @ts-ignore
              userUid: null,
            },
            {
              gameUid: gameUid,
            },
          );

          const command: Command = {
            uid: GuidUtils.generateUuid(),
            recipientTeamUid: teamUid,
            action: actionToDispatch,
            executed: false,
          };
          this.commandRepository.sendCommand(command, {
            gameUid: gameUid,
          });
          this.toast.success(
            this.translate.instant(
              'chat.specialActions.disconnectPlayer.toast.success',
            ),
          );
        });
    }
  }
}
