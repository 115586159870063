import { Injectable, inject } from '@angular/core';
import { CommonRepositoryAbstract } from '@freddy/common';
import { Observable } from 'rxjs';
import { FirebaseChat } from '@freddy/models';
import { TenantService } from '../../../core/auth/services/tenant.service';
import { GameRepository } from '../../game/repository/game.repository';
import {
  Firestore,
  QueryConstraint,
  orderBy,
  where,
} from '@angular/fire/firestore';
import { ChatState } from '../store/chat.store';

@Injectable({
  providedIn: 'root',
})
export class ChatRepository extends CommonRepositoryAbstract<FirebaseChat> {
  private readonly tenantService = inject(TenantService);

  constructor() {
    const firestore = inject(Firestore);

    super(firestore);
  }

  static DOC_PATH = 'chats';

  public getChats(gameUid: string): Observable<FirebaseChat[]> {
    const queryConstraints: QueryConstraint[] = [
      orderBy('lastMessageAt', 'desc'),
      where('participants', 'array-contains', ChatState.ADMIN_CHAT_ID),
    ];
    return this.getCollectionsChanges({ gameUid }, queryConstraints);
  }

  getDocPath(): string {
    return (
      this.tenantService.getOrganizationPrefixPath() +
      `${GameRepository.DOC_PATH}/:gameUid/${ChatRepository.DOC_PATH}`
    );
  }
}
